// TODO: refactor, but can be done later. Porting from exchange does not have rules-of-hooks eslint rule
// TODO: disabling it for now, because it causes eslint errors.
/* eslint-disable react-hooks/rules-of-hooks */
import * as d3 from "d3";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { BackgroundUnderLinePlot } from "./background-under-line-plot";
import Chart from "./chart";
import { useGraphDimensions } from "./hooks/use-graph-dimensions";
import { Label } from "./label";
import { LinePlot } from "./line-plot";
import { Marks } from "./marks";
import { MobilePriceLegend } from "./mobile-price-legend";
import { PriceIndicator } from "./price-indicator";
import { PriceLegend } from "./price-legend";
import {
	Dimensions,
	GRAPH_TYPE,
	GraphData,
	INTERVAL,
	IntervalData,
	PERIOD,
	SeriesData,
	SeriesIntervalData,
} from "./pricing-graph.types";
import { TimePeriod } from "./time-period";
import Toggle from "./toggle";
import { VerticalLine } from "./vertical-line";
import { XAxis } from "./x-axis";
import { YAxis } from "./y-axis";

interface Props {
	graphData?: GraphData;
	dimensions?: Partial<Dimensions>;
	isLoading?: boolean;
	isError?: boolean;
}

export const PricingGraph = ({
	graphData,
	dimensions,
	isLoading,
	isError,
}: Props) => {
	if (isError) {
		return <NoDataGraph />;
	}

	if (isLoading) {
		return <DataLoading />;
	}

	if (
		!graphData ||
		(graphData.graphType !== GRAPH_TYPE.ASK &&
			graphData.graphType !== GRAPH_TYPE.MARKET) ||
		(graphData.daily.ask.length === 0 &&
			graphData.daily.bid.length === 0 &&
			graphData.daily.trade.length === 0)
	) {
		return <NoDataGraph />;
	}

	// Remove null values and parse date to start of day
	const { listingDate } = useMemo(() => {
		const listingDate = graphData?.listingDate
			? moment(graphData?.listingDate).startOf("day")
			: null;

		return { listingDate };
	}, [graphData]);
	const graphType = graphData.graphType;

	const dailyData = useMemoData(graphData.daily);
	const weeklyData = useMemoData(graphData.weekly);
	const monthlyData = useMemoData(graphData.monthly);

	if (!dailyData.ask && !dailyData.bid && !dailyData.trade) {
		return <NoDataGraph />;
	}

	// time period selection
	// Enable options only when there's sufficient data
	function getLegalTimePeriodOptions() {
		if (!graphData) return [];

		const earliestOrderDate = moment.min(
			[...graphData.daily.ask, ...graphData.daily.bid].map(({ date }) =>
				moment(date),
			),
		);

		const legalOptions = [];
		if (moment().diff(earliestOrderDate, "months") >= 3) {
			legalOptions.push(PERIOD.THREE_MONTHS);
		}
		if (moment().diff(earliestOrderDate, "months") >= 6) {
			legalOptions.push(PERIOD.SIX_MONTHS);
		}
		if (moment().startOf("year").startOf("day").isAfter(earliestOrderDate)) {
			legalOptions.push(PERIOD.YEAR_TO_DATE);
		}
		legalOptions.push(PERIOD.MAX);

		return legalOptions;
	}

	const INITIAL_PERIOD = PERIOD.MAX;

	const legalTimePeriodOptions = getLegalTimePeriodOptions();
	const [periodSelected, setPeriodSelected] = useState(INITIAL_PERIOD);

	const svgRef = useRef<SVGSVGElement | null>(null);

	// resize pricing graph
	const [ref, dms] = useGraphDimensions({
		marginTop: 0,
		marginRight: 0,
		marginBottom: 0,
		marginLeft: 0,
		...dimensions,
	});
	const combinedDimensions = dms as Dimensions;
	const { boundedHeight, boundedWidth, marginLeft, marginTop } =
		combinedDimensions;

	const [currentGlobalZoomState, setCurrentGlobalZoomState] = useState(
		d3.zoomIdentity,
	);
	const currentGlobalZoomStateRef = useRef<d3.ZoomTransform>();
	currentGlobalZoomStateRef.current = currentGlobalZoomState;
	const [currentYZoomState, setCurrentYZoomState] = useState(d3.zoomIdentity);
	const [currentXZoomState, setCurrentXZoomState] = useState(d3.zoomIdentity);

	const [intervalSelected, setIntervalSelected] = useState(INTERVAL.MONTHLY);

	const [dataSelected, setDataSelected] = useState(monthlyData);

	useEffect(() => {
		if (intervalSelected === INTERVAL.MONTHLY) {
			setDataSelected(monthlyData);
		} else if (intervalSelected === INTERVAL.WEEKLY) {
			setDataSelected(weeklyData);
		} else {
			setDataSelected(dailyData);
		}
	}, [intervalSelected]);

	// configuration for graph type
	const isAskGraph = graphType === GRAPH_TYPE.ASK;
	const [lineSeriesSelected, setLineSeriesSelected] = useState(
		isAskGraph ? dataSelected.ask : dataSelected.trade,
	);
	useEffect(() => {
		if (isAskGraph) setLineSeriesSelected(dataSelected.ask);
		else setLineSeriesSelected(dataSelected.trade);
	}, [graphType, dataSelected]);

	// Calculate x and y scales
	const { xScale, yScale } = useMemo(() => {
		const xDomain = calculateXDomain(monthlyData);
		const xScale = d3.scaleTime().domain(xDomain).range([0, boundedWidth]);

		const allValues = [
			...dailyData.ask,
			...dailyData.bid,
			...dailyData.trade,
		].map(({ value }) => value);
		const max = Math.max(...allValues);
		const min = Math.min(...allValues);
		const diff = max - min;
		let LOWER_LIMIT = min - 0.1 * diff;
		let UPPER_LIMIT = max + 0.2 * diff;
		if (LOWER_LIMIT === UPPER_LIMIT) {
			LOWER_LIMIT = min / 1.1;
			UPPER_LIMIT = max * 1.2;
		}
		const yDomain = [
			parseFloat((LOWER_LIMIT < 0 ? 0 : LOWER_LIMIT).toFixed(2)),
			parseFloat(UPPER_LIMIT.toFixed(2)),
		];
		const yScale = d3
			.scaleLinear()
			.domain(yDomain)
			.nice()
			.range([boundedHeight, 0]);

		return { xScale, yScale };
	}, [dailyData, combinedDimensions]);

	const { xDomain: xScaleDomainDaily } = useMemo(() => {
		return { xDomain: calculateXDomain(dailyData) };
	}, [dailyData]);
	const { xDomain: xScaleDomainWeekly } = useMemo(() => {
		return { xDomain: calculateXDomain(weeklyData) };
	}, [weeklyData]);
	const { xDomain: xScaleDomainMonthly } = useMemo(() => {
		return { xDomain: calculateXDomain(monthlyData) };
	}, [monthlyData]);
	const [xDomainSelected, setXDomainSelected] = useState(xScaleDomainMonthly);
	useEffect(() => {
		if (intervalSelected === INTERVAL.MONTHLY) {
			setXDomainSelected(xScaleDomainMonthly);
		} else if (intervalSelected === INTERVAL.WEEKLY) {
			setXDomainSelected(xScaleDomainWeekly);
		} else {
			setXDomainSelected(xScaleDomainDaily);
		}
	}, [intervalSelected]);

	// Calculate linestopoffset
	const [lineStopOffsetSelected, setLineStopOffsetSelected] = useState<
		number | null
	>(calculateLineStopOffset(xScaleDomainMonthly, monthlyData));

	function calculateLineStopOffset(xDomain: Moment[], data: IntervalData) {
		if (!listingDate) return null;

		const series = isAskGraph ? data.ask : data.trade;
		if (!series || series.length === 0) return null;

		const x2 = xScale.copy();
		x2.domain(xDomain);
		const lineFirstDateX = x2(series[0].date);
		const lineStopOffset =
			(x2(listingDate) - lineFirstDateX) / (boundedWidth - lineFirstDateX);

		return lineStopOffset;
	}
	const lineStopOffsetMonthly = useMemo(() => {
		return calculateLineStopOffset(xScaleDomainMonthly, monthlyData);
	}, [listingDate, monthlyData]);
	const lineStopOffsetWeekly = useMemo(() => {
		return calculateLineStopOffset(xScaleDomainWeekly, weeklyData);
	}, [listingDate, weeklyData]);
	const lineStopOffsetDaily = useMemo(() => {
		return calculateLineStopOffset(xScaleDomainDaily, dailyData);
	}, [listingDate, dailyData]);
	useEffect(() => {
		if (intervalSelected === INTERVAL.MONTHLY) {
			setLineStopOffsetSelected(lineStopOffsetMonthly);
		} else if (intervalSelected === INTERVAL.WEEKLY) {
			setLineStopOffsetSelected(lineStopOffsetWeekly);
		} else {
			setLineStopOffsetSelected(lineStopOffsetDaily);
		}
	}, [intervalSelected, boundedWidth]);

	// * Left offset to account for y-axis value
	const [yAxisValueLeftOffset, setYAxisValueLeftOffset] = useState<number>(0);
	// * Account for y-axis label indicator
	const yAxisLabelIndicatorPadding = 4;
	useEffect(() => {
		// * Calculate left offset based on maximum value
		const yAxisMaxLength = yScale.domain()[1].toFixed(2).length;
		// const yAxisMaxLength = 4;
		// * Account for decimal separator
		const decimalSeparatorOffset = 2;
		// * Account for thousands separator (7 has 1 separator, every 3 after that adds 1)
		const thousandsSeparatorOffset = 2;
		const numberOfThousandsSeparator = Math.floor((yAxisMaxLength - 4) / 3);
		// * Finally, a mathematically-accurate offset
		const yAxisValueLeftOffset = Math.ceil(
			yAxisMaxLength * 6 +
				decimalSeparatorOffset +
				thousandsSeparatorOffset * numberOfThousandsSeparator +
				yAxisLabelIndicatorPadding,
		);
		setYAxisValueLeftOffset(yAxisValueLeftOffset);
	}, [yScale]);

	// * Pointer event handlers
	const [mouseY, setMouseY] = useState<number | null>(null);
	const [mouseX, setMouseX] = useState<number | null>(null);
	const [hasMountedLongPressTimer, setHasMountedLongPressTimer] =
		useState<boolean>(false);
	const longPressTimeActivationThreshold = 400;
	const longPressMovementThreshold = 5;
	const longPressDeactivationDelay = 100;
	const [longPressStartingPoint, setLongPressStartingPoint] = useState<{
		x: number;
		y: number;
	} | null>(null);
	const [longPressTimerId, setLongPressTimerId] = useState<number | null>(null);
	const [longPressActivated, setLongPressActivated] = useState<boolean>(false);
	const longPressActivatedRef = useRef<boolean>();
	longPressActivatedRef.current = longPressActivated;
	const touchSelectDataPoint = (
		event: React.TouchEvent,
		targetBoundingRect: DOMRect,
	) => {
		const leftOffset = targetBoundingRect.left + marginLeft;
		const mouseX = event.touches[0].clientX - leftOffset;
		setMouseX(mouseX);

		const topOffset = targetBoundingRect.top + marginTop;
		const mouseY = event.touches[0].clientY - topOffset;
		setMouseY(mouseY);
	};
	const onTouchStartCapture = (event: React.TouchEvent) => {
		if (hasMountedLongPressTimer) return;

		// * Unmount timeout if there are multiple touches
		if (event.touches.length > 1) {
			if (longPressTimerId) {
				clearTimeout(longPressTimerId);
				setLongPressTimerId(null);
			}
			return;
		}

		// * Mount timeout for long press activation
		const { clientX, clientY } = event.touches[0];
		setLongPressStartingPoint({ x: clientX, y: clientY });
		const targetBoundingRect = event.currentTarget.getBoundingClientRect();
		const timerId = window.setTimeout(() => {
			setLongPressActivated(true);
			touchSelectDataPoint(event, targetBoundingRect);
		}, longPressTimeActivationThreshold);
		setHasMountedLongPressTimer(true);
		setLongPressTimerId(timerId);
	};
	const onTouchMoveCapture = (event: React.TouchEvent) => {
		if (longPressActivated) {
			const targetBoundingRect = event.currentTarget.getBoundingClientRect();
			touchSelectDataPoint(event, targetBoundingRect);
			return;
		}

		// * If timer still exists, check if moved out of threshold
		if (longPressTimerId && longPressStartingPoint) {
			const { clientX, clientY } = event.touches[0];
			const movementX = clientX - longPressStartingPoint.x;
			const movementY = clientY - longPressStartingPoint.y;

			// * If moved out of threshold, clear longPress timeout
			if (
				movementX > longPressMovementThreshold ||
				movementY > longPressMovementThreshold ||
				movementX < -longPressMovementThreshold ||
				movementY < -longPressMovementThreshold
			) {
				clearTimeout(longPressTimerId);
				setLongPressTimerId(null);
			}
		}
	};
	const onTouchEndCapture = (event: React.TouchEvent) => {
		window.setTimeout(() => {
			setMouseY(null);
			setMouseX(null);
		}, longPressDeactivationDelay);

		if (event.touches.length === 0) {
			setHasMountedLongPressTimer(false);
			setLongPressActivated(false);
			if (longPressTimerId) {
				clearTimeout(longPressTimerId);
				setLongPressTimerId(null);
			}
		}
	};
	const onPointerMove = (event: React.PointerEvent) => {
		if (event.pointerType === "mouse") {
			const boundingEvent = event.currentTarget.getBoundingClientRect();
			const mouseX =
				event.clientX - boundingEvent.left - marginLeft;
			const mouseY = event.clientY - boundingEvent.top - marginTop;
			setMouseX(mouseX);
			setMouseY(mouseY);
		}
	};
	const onMouseLeave = () => {
		setMouseY(null);
		setMouseX(null);
	};

	// Toggle
	const initToggle = [
		{ name: "toggle-bid", value: true },
		{ name: "toggle-ask", value: true },
		{ name: "toggle-closed-trades", value: true },
	];
	const [toggle, setToggle] = useState(initToggle);
	const toggleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name } = e.target;
		setToggle(
			toggle.map((v) => {
				if (v.name === name) {
					return {
						name: v.name,
						value: !v.value,
					};
				}
				return v;
			}),
		);
	};

	const toggleIndicator = {
		ask: !!toggle.find((f) => f.name === "toggle-ask")?.value,
		bid: !!toggle.find((f) => f.name === "toggle-bid")?.value,
		trades: !!toggle.find((f) => f.name === "toggle-closed-trades")?.value,
	};

	// Zoom
	if (currentXZoomState) {
		const newXScale = currentXZoomState.rescaleX(
			xScale.domain(xDomainSelected),
		);
		xScale.domain(newXScale.domain());
	}

	const zoomGlobal: d3.ZoomBehavior<SVGSVGElement, unknown> = d3
		.zoom<SVGSVGElement, unknown>()
		.scaleExtent([1, 500])
		.on("zoom", (event) => {
			// If longPressActivated, don't zoom
			if (longPressActivatedRef.current && currentGlobalZoomStateRef.current) {
				// Use ref.current to get latest stateful value
				const { k: refPrevK, x: refPrevX } = currentGlobalZoomStateRef.current;
				event.transform.k = refPrevK;
				event.transform.x = refPrevX;
				return;
			}

			const { k: newK, x: newX } = event.transform;
			const { k: prevK, x: prevX } = currentGlobalZoomState;

			// Calculate the new x position after panning
			const PAN_X_LIMIT_LEFT =
				boundedWidth * 0.15 + yAxisValueLeftOffset;
			const PAN_X_LIMIT_RIGHT = boundedWidth * 0.15;
			let deltaX = newX - prevX;
			// * Catch over-panning
			if (newX > PAN_X_LIMIT_RIGHT) {
				// right
				deltaX = PAN_X_LIMIT_RIGHT - prevX;
				event.transform.x = deltaX;
			} else if (
				-newX + boundedWidth >
				boundedWidth * newK + PAN_X_LIMIT_LEFT
			) {
				// left
				deltaX = -(
					boundedWidth * newK +
					PAN_X_LIMIT_LEFT -
					prevX -
					boundedWidth
				);
				event.transform.x = deltaX;
			}

			setCurrentXZoomState(
				currentXZoomState.translate(deltaX / prevK, 0).scale(newK / prevK),
			);

			setCurrentGlobalZoomState(event.transform);
		});
	const [hasAttachedZoomBehavior, setHasAttachedZoomBehavior] =
		useState<boolean>(false);
	useEffect(() => {
		// This useEffect is for attaching zoom behavior
		if (hasAttachedZoomBehavior) return;

		// Don't do anything if graph is not loaded
		if (xScale.range()[1] <= 0 || xScale.range()[1] <= 0) return;
		if (!currentXZoomState || !currentYZoomState) return;

		const svg = d3.select<SVGSVGElement, unknown>(svgRef.current!);
		svg.call(zoomGlobal);
		setHasAttachedZoomBehavior(true);

		const resetListener = d3.select(".reset-listening-rect");
		resetListener.on("contextmenu ", (e) => {
			e.preventDefault();
			svg.call(zoomGlobal.transform, d3.zoomIdentity);
			setCurrentGlobalZoomState(d3.zoomIdentity);
			setCurrentXZoomState(d3.zoomIdentity);
			setCurrentYZoomState(d3.zoomIdentity);
		});

		return () => {
			resetListener.on("contextmenu ", null);
		};
	}, [xScale, yScale]);

	const [hasZoomedOnLoad, setHasZoomedOnLoad] = useState<boolean>(false);
	useEffect(() => {
		// This useEffect is for zooming on load
		if (hasZoomedOnLoad) return;

		// Don't do anything if graph is not loaded
		if (xScale.range()[1] <= 0 || xScale.range()[1] <= 0) return;
		if (!currentXZoomState || !currentYZoomState) return;

		zoomAndPanToTimePeriod(INITIAL_PERIOD, INTERVAL.MONTHLY);
		setHasZoomedOnLoad(true);
	}, [xScale, yScale]);

	function zoomAndPanToTimePeriod(period: PERIOD, interval: INTERVAL): void {
		const svg = d3.select<SVGSVGElement, unknown>(svgRef.current!);

		const x2 = xScale.copy();

		let dataStartDate = xScaleDomainDaily[0];
		switch (interval) {
			case INTERVAL.MONTHLY:
				dataStartDate = xScaleDomainMonthly[0];
				x2.domain(xScaleDomainMonthly);
				break;
			case INTERVAL.WEEKLY:
				dataStartDate = xScaleDomainWeekly[0];
				x2.domain(xScaleDomainWeekly);
				break;
			default:
				dataStartDate = xScaleDomainDaily[0];
				x2.domain(xScaleDomainDaily);
		}

		let windowStartDate: moment.Moment;
		/* eslint-disable indent */
		switch (period) {
			case PERIOD.THREE_MONTHS:
				windowStartDate = moment().startOf("day").subtract(3, "months");
				break;
			case PERIOD.SIX_MONTHS:
				windowStartDate = moment().startOf("day").subtract(6, "months");
				break;
			case PERIOD.YEAR_TO_DATE:
				windowStartDate = moment().startOf("day").startOf("year");
				break;
			case PERIOD.MAX:
				windowStartDate = dataStartDate;
				break;
		}

		/* eslint-enable indent */
		const scale = calculateScale(windowStartDate, dataStartDate, interval);

		const targetX =
			x2(windowStartDate) - x2(dataStartDate) / currentXZoomState.k;

		svg
			.transition()
			.call(zoomGlobal.scaleTo, scale)
			.transition()
			.call(zoomGlobal.translateTo, targetX, 0, [0, 0]);
	}
	function calculateScale(
		windowStartDate: moment.Moment,
		dataStartDate: moment.Moment,
		interval: INTERVAL,
	) {
		if (interval === INTERVAL.MONTHLY) {
			const currentMonth = moment().startOf("month");
			return (
				(dataStartDate.diff(currentMonth, "month") - 1) /
				(windowStartDate.diff(currentMonth, "month") - 1)
			);
		} else if (interval === INTERVAL.WEEKLY) {
			const currentWeek = moment().startOf("week");
			return (
				(dataStartDate.diff(currentWeek, "week") - 1) /
				(windowStartDate.diff(currentWeek, "week") - 1)
			);
		} else {
			const today = moment().startOf("day");
			return dataStartDate.diff(today) / windowStartDate.diff(today);
		}
	}

	return (
		<>
			<div className="sm:hidden mb-4 min-h-[92px]">
				<MobilePriceLegend
					mouseX={mouseX}
					data={dataSelected}
					listingDate={graphData.listingDate}
					dimensions={combinedDimensions}
					xScale={xScale}
					yScale={yScale}
					isAskGraph={isAskGraph}
					toggleIndicator={toggleIndicator}
				/>
			</div>
			<div className="flex h-full w-full flex-col">
				<TimePeriod
					periodSelected={periodSelected}
					setPeriodSelected={setPeriodSelected}
					zoomAndPanToTimePeriod={zoomAndPanToTimePeriod}
					options={legalTimePeriodOptions}
					setIntervalSelected={setIntervalSelected}
				/>

				<div
					ref={ref}
					className="grow select-none"
					onPointerMove={onPointerMove}
					onMouseLeave={onMouseLeave}
					onTouchStartCapture={onTouchStartCapture}
					onTouchMoveCapture={onTouchMoveCapture}
					onTouchEndCapture={onTouchEndCapture}
				>
					<div className="h-0 w-0">
						<Chart dimensions={combinedDimensions} svgRef={svgRef}>
							<YAxis
								range={[0, boundedHeight]}
								scale={yScale}
								dimensions={combinedDimensions}
							/>

							<XAxis
								dates={lineSeriesSelected.map((data) => data.date)}
								boundedWidth={boundedWidth}
								scale={xScale}
								dimensions={combinedDimensions}
								earliestDataDate={moment
									.min(
										[
											...dailyData.ask,
											...dailyData.bid,
											...dailyData.trade,
										].map((d) => d.date),
									)
									.startOf(
										INTERVAL.MONTHLY
											? "month"
											: INTERVAL.WEEKLY
												? "week"
												: "day",
									)}
							/>

							<g clipPath="url(#clip)">
								{((isAskGraph && toggleIndicator.ask) ||
									(!isAskGraph && toggleIndicator.trades)) && (
										<>
											<LinePlot
												data={lineSeriesSelected}
												xScale={xScale}
												yScale={yScale}
												stopOffset={lineStopOffsetSelected}
											/>
											<BackgroundUnderLinePlot
												data={lineSeriesSelected}
												xScale={xScale}
												yScale={yScale}
												height={boundedHeight}
											/>

											{lineSeriesSelected?.map(
												(data, index) =>
													!data.isExtrapolated && (
														<Marks
															key={index}
															xScale={xScale}
															yScale={yScale}
															data={data}
															index={index}
															name={isAskGraph ? "ask" : "trade"}
															isAskGraph={isAskGraph}
														/>
													),
											)}
										</>
									)}

								{toggleIndicator.bid &&
									dataSelected.bid?.map((data, index) => (
										<Marks
											key={index}
											xScale={xScale}
											yScale={yScale}
											data={data}
											index={index}
											name={"bid"}
											isAskGraph={isAskGraph}
										/>
									))}

								{isAskGraph &&
									toggleIndicator.trades &&
									dataSelected.trade?.map((data, index) => (
										<Marks
											key={index}
											xScale={xScale}
											yScale={yScale}
											data={data}
											index={index}
											name={"trade"}
											isAskGraph={isAskGraph}
										/>
									))}

								{!isAskGraph &&
									toggleIndicator.ask &&
									dataSelected.ask?.map((data, index) => (
										<Marks
											key={index}
											xScale={xScale}
											yScale={yScale}
											data={data}
											index={index}
											name={"ask"}
											isAskGraph={isAskGraph}
										/>
									))}
							</g>

							<g clipPath="url(#listing-date-clip-path)">
								{listingDate && (
									<>
										<VerticalLine
											x={xScale(listingDate)}
											y={0}
											dimensions={combinedDimensions}
										/>

										<Label text={"LISTING DATE"} x={xScale(listingDate)} />
									</>
								)}
							</g>

							<g clipPath="url(#price-legend-clip-path)">
								<PriceLegend
									mouseX={mouseX}
									data={dataSelected}
									listingDate={graphData.listingDate}
									xScale={xScale}
									yScale={yScale}
									intervalSelected={intervalSelected}
									isAskGraph={isAskGraph}
									toggleIndicator={toggleIndicator}
									dimensions={combinedDimensions}
								/>
							</g>

							<g clipPath="url(#price-indicator-clip-path)">
								{mouseY && (
									<PriceIndicator
										mouseY={mouseY}
										scale={yScale}
										dimensions={combinedDimensions}
										yAxisValueLeftOffset={yAxisValueLeftOffset}
									/>
								)}
							</g>

							<g transform={`translate(${-yAxisValueLeftOffset},0)`}>
								<rect
									className="reset-listening-rect"
									width={boundedWidth}
									height={boundedHeight}
									x={marginLeft}
									y={marginTop}
									fill="transparent"
								/>
							</g>
						</Chart>
					</div>
				</div>
				<Toggle
					toggleIndicator={toggleIndicator}
					onChange={toggleOnChange}
					isAskGraph={isAskGraph}
				/>
			</div>
		</>
	);
};

export const NoDataGraph = () => (
	<div className="w-full h-1/2 flex justify-center items-center">
		<h3 className="text-center text-xl font-bold text-gray-500">
			No data available
		</h3>
	</div>
);

export const DataLoading = () => (
	<div className="flex h-full w-full animate-pulse items-center justify-center rounded-lg bg-gray-100">
		<svg
			className="mr-3 h-5 w-5 animate-spin bg-slate-500"
			viewBox="0 0 24 24"
		></svg>
		<p className="font-bold text-slate-500">Fetching the graph data...</p>
	</div>
);

// Remove null values and parse date to start of day
export function parseDataToStartOfDay(series: SeriesData[]) {
	return series
		?.filter((data) => data?.value !== null)
		?.map((data) => ({
			...data,
			date: moment(data?.date).startOf("day"),
		}));
}

export function useMemoData(data: SeriesIntervalData) {
	const { ask, bid, trade } = useMemo(() => {
		return {
			ask: parseDataToStartOfDay(data.ask),
			bid: parseDataToStartOfDay(data.bid),
			trade: parseDataToStartOfDay(data.trade),
		};
	}, [data]);

	return { ask, bid, trade };
}

export function calculateXDomain(data: IntervalData) {
	const pushDates = ({
		datesArr,
		startDates,
		endDates,
	}: {
		datesArr: { date: moment.Moment; value: number }[];
		startDates: moment.Moment[];
		endDates: moment.Moment[];
	}) => {
		if (datesArr && datesArr.length > 0) {
			if (datesArr[0]?.date) startDates.push(datesArr[0]?.date);
			if (datesArr[datesArr.length - 1]?.date)
				endDates.push(datesArr[datesArr.length - 1]?.date);
		}
	};

	const startDates: moment.Moment[] = [],
		endDates: moment.Moment[] = [];
	pushDates({ datesArr: data.ask, startDates, endDates });
	pushDates({ datesArr: data.bid, startDates, endDates });
	pushDates({ datesArr: data.trade, startDates, endDates });

	const xDomain = [moment.min(startDates), moment.max(endDates)];
	return xDomain;
}
